
import {
    defineComponent,
    ref,
    watch,
    onMounted,
    reactive,
    toRefs,
    computed,
    nextTick,
} from 'vue';
import DM from '../components/downloadModal.vue';
import BuSelector from '@/views/SalesReporting/components/BuSelector.vue';
import { getCheckingRoundList } from '@/API/salesReporting/claimCheckingReport';
import moment, { Moment } from 'moment';
import { message, TreeSelect } from 'ant-design-vue';
import { qmOptions } from '@/views/SalesReporting/type';
import {
    getFailedReasonAnalysis,
    getRetrieveRegionList,
    getRetrieveSubRegionList,
    getDealerByBUID,
} from '@/API/salesReporting';
import { getOfferType } from '@/API/claim/delaerClaim/delaer';
import { useStore } from 'vuex';
import downloadFile from '@/utils/claim/downloadFile';
import { useRoute } from 'vue-router';
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
export default defineComponent({
    components: {
        BuSelector,
        DM,
    },
    setup() {
        const usersLst = ref<any[]>([]);
        const temporaryArray = ref<any[]>([]); // 动态下拉框的值
        const offerTypeArray = ref<any[]>([]); //offerType的值
        const allDealer = ref<any[]>([]);
        interface SearchDataType {
            bu: string | null;
            payoutRound: Array<string>;
            payoutRoundYear: string | null;
            payoutRoundMonth: Array<string>;
            year: string | null;
            quarter: Array<string>;
            dataView: string | null;
            dynamic: string | null;
            dynamicArray: Array<string>;
            dynamicArrayObj: Array<any>;
        }

        const searchData = reactive<SearchDataType>({
            bu: '',
            payoutRound: [],
            payoutRoundYear: '',
            payoutRoundMonth: [],
            year: '',
            quarter: [],
            dataView: null,
            dynamic: null,
            dynamicArray: [],
            dynamicArrayObj: [],
        });
        const store = useStore();
        const buNameIdMap = computed(() => {
            const buLst = store.state.user.buList.reduce(
                (maps: any, bu: any) => {
                    return { ...maps, [bu.nameCn]: bu.id };
                },
                {}
            );
            return buLst;
        });
        //payoutRound初始化
        const payoutRoundList = ref([]);
        const getCheckingRound = (buName: string) => {
            getCheckingRoundList({ buLst: buNameIdMap.value[buName] }).then(
                (res: any) => {
                    payoutRoundList.value = [];
                    payoutRoundList.value = res;
                }
            );
        };

        const isPayoutRound = computed(() => {
            return payoutRoundList.value.length;
        });

        const selectPayoutRound = (value: string) => {
            getCheckingRound(value);
        };

        const openPayoutRoundYear = ref<boolean>(false);

        const handlerOpenPayoutRoundChange = (status: any): void => {
            openPayoutRoundYear.value = status;
        };
        const handlerPanelPayoutRoundChange = (value: string): void => {
            const time = moment(value).format('YYYY');
            searchData.payoutRoundYear = time as string;
            openPayoutRoundYear.value = false;
        };

        //period初始化
        const openProgramPeriodYear = ref<boolean>(false);
        const handlerOpenProgramPeriodChange = (status: any): void => {
            openProgramPeriodYear.value = status;
        };
        const handlerPanelProgramPeriodChange = (value: string): void => {
            const time = moment(value).format('YYYY');
            searchData.year = time as string;
            openProgramPeriodYear.value = false;
        };

        let resetBu = '';
        const initValue = (value: string) => {
            searchData.bu = value;
            resetBu = value;
            getCheckingRound(value);
        };

        const getMonth = (quarter: number) => {
            let array: Array<string> = [];
            switch (quarter) {
                case 1:
                    array = ['01', '02', '03'];
                    break;
                case 2:
                    array = ['04', '05', '06'];
                    break;
                case 3:
                    array = ['07', '08', '09'];
                    break;
                case 4:
                    array = ['10', '11', '12'];
                    break;
            }
            return array;
        };

        const reset = () => {
            searchData.bu = resetBu;
            (searchData.payoutRound = []), (searchData.year = '');
            searchData.quarter = [];
            searchData.payoutRoundYear = null;
            searchData.payoutRoundMonth = [];
            searchData.dataView = null;
            searchData.dynamic = null;
            searchData.dynamicArray = [];
            searchData.dynamicArrayObj = [];
            searchData.year = moment().format('YYYY');
            searchData.payoutRoundYear = moment().format('YYYY');
            const array = getMonth(moment().quarter());
            searchData.quarter = array;
            searchData.payoutRoundMonth = array;
        };

        const tableHeight = ref(0);

        const mergeIndexProxyClosed = ref<number[]>([]);
        const mergeLengthIndexProxyClosed = ref<number[]>([]);
        const renderContentClosed = ({ text, index }: any) => {
            const obj = {
                children: text,
                props: {} as any,
            };
            if (mergeIndexProxyClosed.value.length) {
                for (let i = 0; i < mergeIndexProxyClosed.value.length; i++) {
                    if (index === mergeIndexProxyClosed.value[i]) {
                        obj.props.rowSpan =
                            mergeLengthIndexProxyClosed.value[i] + 1;
                    }
                    if (
                        index > mergeIndexProxyClosed.value[i] &&
                        index <=
                            mergeIndexProxyClosed.value[i] +
                                mergeLengthIndexProxyClosed.value[i]
                    ) {
                        obj.props.rowSpan = 0;
                    }
                }

                return obj;
            } else {
                return obj;
            }
        };

        const mergeIndexProxyNonClosed = ref<number[]>([]);
        const mergeLengthIndexProxyNonClosed = ref<number[]>([]);
        const renderContentNonClosed = ({ text, index }: any) => {
            const obj = {
                children: text,
                props: {} as any,
            };
            if (mergeIndexProxyNonClosed.value.length) {
                for (
                    let i = 0;
                    i < mergeIndexProxyNonClosed.value.length;
                    i++
                ) {
                    if (index === mergeIndexProxyNonClosed.value[i]) {
                        obj.props.rowSpan =
                            mergeLengthIndexProxyNonClosed.value[i] + 1;
                    }
                    if (
                        index > mergeIndexProxyNonClosed.value[i] &&
                        index <=
                            mergeIndexProxyNonClosed.value[i] +
                                mergeLengthIndexProxyNonClosed.value[i]
                    ) {
                        obj.props.rowSpan = 0;
                    }
                }

                return obj;
            } else {
                return obj;
            }
        };

        const closedColumns = [
            {
                title: 'Rejected Type',
                dataIndex: 'rejectedType',
                customRender: renderContentClosed,
                width: 200,
            },
            {
                title: 'Number of Programs',
                dataIndex: 'programNo',
                customRender: renderContentClosed,
                width: 200,
            },
            {
                title: 'Final Rejected Claims',
                dataIndex: 'rejectedTypeClaims',
                customRender: renderContentClosed,
                width: 150,
            },
            {
                title: 'Proportion%',
                dataIndex: 'rejectedTypeProportionStr',
                customRender: renderContentClosed,
                width: 150,
            },
            {
                title: 'Rejected Reason',
                dataIndex: 'rejectedReason',
            },
            {
                title: 'Final Rejected Claims',
                dataIndex: 'rejectedReasonClaims',
                width: 150,
            },
            {
                title: 'Proportion%',
                dataIndex: 'rejectedReasonProportionStr',
                width: 150,
            },
        ];
        const nonClosedColumns = [
            {
                title: 'Rejected Type',
                dataIndex: 'rejectedType',
                customRender: renderContentNonClosed,
                width: 200,
            },
            {
                title: 'Number of Programs',
                dataIndex: 'programNo',
                customRender: renderContentNonClosed,
                width: 200,
            },
            {
                title: 'Rejected Claims',
                dataIndex: 'rejectedTypeClaims',
                customRender: renderContentNonClosed,
                width: 150,
            },
            {
                title: 'Proportion%',
                dataIndex: 'rejectedTypeProportionStr',
                customRender: renderContentNonClosed,
                width: 150,
            },
            {
                title: 'Rejected Reason',
                dataIndex: 'rejectedReason',
            },
            {
                title: 'Rejected Claims',
                dataIndex: 'rejectedReasonClaims',
                width: 150,
            },
            {
                title: 'Proportion%',
                dataIndex: 'rejectedReasonProportionStr',
                width: 150,
            },
        ];
        const closedDataSource = ref<any>([]);
        const nonClosedDataSource = ref<any>([]);

        const userState = reactive<UserStateType>({
            value: [],
            fetching: false,
            userData: [],
        });
        const clearDynamic = (value: string) => {
            searchData.dynamic = null;
            searchData.dynamicArray = [];
            searchData.dynamicArrayObj = [];
            temporaryArray.value = [];
            userState.userData = [];
            switch (value) {
                case 'Dealer Group':
                    console.log('Dealer Group');
                    getDealerByBUID({
                        buId: buNameIdMap.value[searchData.bu as string],
                    }).then((res) => {
                        const dealerGroupsObj = res.reduce(
                            (obj: Record<string, any>, dealer: any) => {
                                const dealerGroupEn =
                                    dealer.dealerGroup.dealerGroupEn;
                                if (
                                    dealerGroupEn &&
                                    !Object.keys(obj).includes(dealerGroupEn)
                                ) {
                                    obj[dealerGroupEn] = {
                                        label: dealerGroupEn,
                                        value: dealer.dealerGroup.groupId,
                                    };
                                }
                                return obj;
                            },
                            {}
                        );
                        console.log('dealerGroupsObj', dealerGroupsObj);
                        allDealer.value = Object.values(dealerGroupsObj);
                    });
                    break;
                case 'Dealer':
                    getDealerByBUID({
                        buId: buNameIdMap.value[searchData.bu as string],
                    }).then((res) => {
                        const array: Array<any> = [];
                        for (let i = 0; i < res.length; i++) {
                            array.push({
                                label: res[i].dealerInfo.dealerNameCn,
                                value: res[i].dealerInfo.dealerCode,
                            });
                        }
                        allDealer.value = array;
                    });
                    break;
                case 'Region':
                    getRetrieveRegionList(
                        buNameIdMap.value[searchData.bu as string]
                    ).then((res) => {
                        // 北东西南 顺序排序
                        const sortArr: any[] = [[], [], [], [], []];
                        const maps = {
                            n: 0,
                            e: 1,
                            w: 2,
                            s: 3,
                        };
                        res.forEach((region: any) => {
                            const firstLetter = (
                                region.regionEn[0] as string
                            ).toLocaleLowerCase();
                            if (firstLetter in maps) {
                                sortArr[maps[firstLetter]].push(region);
                            } else {
                                sortArr[4].push(region);
                            }
                        });
                        const regionList = sortArr.flat();
                        // console.log(res)
                        const array = [];
                        for (let i = 0; i < regionList.length; i++) {
                            array.push({
                                key: regionList[i].id,
                                id: regionList[i].id,
                                value: regionList[i].regionEn,
                            });
                        }
                        temporaryArray.value = array;
                    });
                    break;
                case 'Sub Region':
                    // console.log(searchData.bu);
                    getRetrieveSubRegionList(
                        buNameIdMap.value[searchData.bu as string]
                    ).then((res) => {
                        // 北东西南 顺序排序
                        const sortArr: any[] = [[], [], [], [], []];
                        const maps = {
                            n: 0,
                            e: 1,
                            w: 2,
                            s: 3,
                        };
                        res.forEach((region: any) => {
                            const firstLetter = (
                                region.regionEn[0] as string
                            ).toLocaleLowerCase();
                            if (firstLetter in maps) {
                                sortArr[maps[firstLetter]].push(region);
                            } else {
                                sortArr[4].push(region);
                            }
                        });
                        const regionList = sortArr.flat();
                        // console.log(res)
                        const array = [];
                        for (let i = 0; i < regionList.length; i++) {
                            array.push({
                                key: regionList[i].id,
                                id: regionList[i].id,
                                value: regionList[i].regionEn,
                            });
                        }
                        temporaryArray.value = array;
                    });
                    break;
                case 'Program Category':
                    console.log('rogram Category');
                    temporaryArray.value = offerTypeArray.value;
                    break;
            }

            // temporaryArray
        };

        getOfferType().then((res: any): void => {
            for (
                let i = 0;
                i < res.incentiveCategory[0].offerType.length;
                i++
            ) {
                offerTypeArray.value.push({
                    key: res.incentiveCategory[0].offerType[i].pathKey,
                    value: res.incentiveCategory[0].offerType[i].nameEn,
                    id: res.incentiveCategory[0].offerType[i].id,
                });
            }
            for (
                let i = 0;
                i < res.incentiveCategory[1].offerType.length;
                i++
            ) {
                offerTypeArray.value.push({
                    key: res.incentiveCategory[1].offerType[i].pathKey,
                    value: res.incentiveCategory[1].offerType[i].nameEn,
                    id: res.incentiveCategory[1].offerType[i].id,
                });
            }
            // offerTypeArray.value.push(res.incentiveCategory[0])
        });

        const downloadTypeVisible = ref(false);
        const downloadType = ref(1);

        const download = () => {
            downloadTypeVisible.value = true;
        };

        interface ParamType {
            language: string;
            currentDealerCodes: Array<string>;
            region: Array<string>;
            subRegion: Array<string>;
            programCategory: Array<string>;
            bu: Array<string>;
            programPeriod: string;
            dealerGroup: Array<string>;
            // dealerName: Array<string>;
            downType: number | null;
            payoutRoundYear: string | null;
            payoutRoundMonth: Array<string>;
            programPeriodYear: string | null;
            programPeriodMonth: Array<string>;
            size: number;
        }

        const getParams = (isDownload: boolean) => {
            const array = [];
            for (let i = 0; i < searchData.payoutRoundMonth.length; i++) {
                if (searchData.payoutRoundMonth[i][0] == 'Q') {
                    const quarter = parseInt(searchData.payoutRoundMonth[i][1]);
                    if (quarter == 4) {
                        array.push(
                            searchData.payoutRoundYear +
                                '-' +
                                ((quarter - 1) * 3 + 1)
                        );
                        array.push(
                            searchData.payoutRoundYear +
                                '-' +
                                ((quarter - 1) * 3 + 3)
                        );
                        array.push(
                            searchData.payoutRoundYear +
                                '-' +
                                ((quarter - 1) * 3 + 2)
                        );
                    } else {
                        array.push(
                            searchData.payoutRoundYear +
                                '-0' +
                                ((quarter - 1) * 3 + 1)
                        );
                        array.push(
                            searchData.payoutRoundYear +
                                '-0' +
                                ((quarter - 1) * 3 + 3)
                        );
                        array.push(
                            searchData.payoutRoundYear +
                                '-0' +
                                ((quarter - 1) * 3 + 2)
                        );
                    }
                } else {
                    array.push(
                        searchData.payoutRoundYear +
                            '-' +
                            searchData.payoutRoundMonth[i]
                    );
                }
            }
            const array2 = [];
            for (let i = 0; i < searchData.quarter.length; i++) {
                if (searchData.quarter[i][0] == 'Q') {
                    const quarter = parseInt(searchData.quarter[i][1]);
                    if (quarter == 4) {
                        array2.push(
                            searchData.year + '-' + ((quarter - 1) * 3 + 1)
                        );
                        array2.push(
                            searchData.year + '-' + ((quarter - 1) * 3 + 3)
                        );
                        array2.push(
                            searchData.year + '-' + ((quarter - 1) * 3 + 2)
                        );
                    } else {
                        array2.push(
                            searchData.year + '-0' + ((quarter - 1) * 3 + 1)
                        );
                        array2.push(
                            searchData.year + '-0' + ((quarter - 1) * 3 + 3)
                        );
                        array2.push(
                            searchData.year + '-0' + ((quarter - 1) * 3 + 2)
                        );
                    }
                } else {
                    array2.push(searchData.year + '-' + searchData.quarter[i]);
                }
            }
            const params: ParamType = {
                // payoutRound: searchData.payoutRound,
                currentDealerCodes: [],
                language: 'EN',
                region: [],
                subRegion: [],
                programCategory: [],
                bu: [searchData.bu as string],
                programPeriod: '',
                dealerGroup: [],
                // dealerName: [],
                downType: null,
                payoutRoundYear: searchData.payoutRoundYear,
                payoutRoundMonth: array,
                programPeriodYear: searchData.year,
                programPeriodMonth: array2,
                size: -1,
            };

            let arrayObj: Array<string> = [];
            switch (searchData.dataView as any) {
                case 'Dealer Group':
                    arrayObj = [];
                    for (
                        let i = 0;
                        i < searchData.dynamicArrayObj.length;
                        i++
                    ) {
                        arrayObj.push(searchData.dynamicArrayObj[i].key);
                    }
                    params.dealerGroup = arrayObj;
                    break;
                case 'Dealer':
                    arrayObj = [];
                    for (
                        let i = 0;
                        i < searchData.dynamicArrayObj.length;
                        i++
                    ) {
                        arrayObj.push(searchData.dynamicArrayObj[i].key);
                    }
                    params.currentDealerCodes = arrayObj;
                    break;
                case 'Region':
                    params.region = searchData.dynamicArray;
                    break;
                case 'Sub Region':
                    params.subRegion = searchData.dynamicArray;
                    break;
                case 'Program Category':
                    params.programCategory = searchData.dynamicArray;
                    break;
            }

            if (isDownload) {
                params.downType = downloadType.value;
            }
            return params;
        };

        const handleOk = (data: Array<any>) => {
            //
            if (data.length > 1) {
                downloadType.value = 3;
            } else {
                downloadType.value = data[0].sign;
            }
            const params = getParams(true);
            const downloadParams = {
                url: `/claimapi/failedReasonAnalysis/download`,
                method: 'post',
                params: params,
            };
            downloadFile(
                downloadParams,
                'application/vnd-excel; char-set=UTF-8'
            ).then((res: any) => {
                downloadTypeVisible.value = false;
            });
        };

        const calculateMergeIndex = (data: any) => {
            const mergeIndex = []; //需要合并的起始角标
            const mergeLengthIndex = []; //起始角标下多少个
            let isMerge = false; //是否有合并了
            let isMergeNum = 0; //记录合并了几个

            if (data) {
                for (let i = 0; i < data.length - 2; i++) {
                    const data1 = data[i];
                    const data2 = data[i + 1];
                    if (data1.rejectedType === data2.rejectedType && isMerge) {
                        isMerge = true;
                        isMergeNum++;
                    }
                    if (data1.rejectedType === data2.rejectedType && !isMerge) {
                        isMerge = true;
                        isMergeNum++;
                        mergeIndex.push(i);
                    }

                    if (data1.rejectedType !== data2.rejectedType && isMerge) {
                        mergeLengthIndex.push(isMergeNum);
                        isMergeNum = 0;
                        isMerge = false;
                    }
                    if (i === data.length - 3 && isMerge) {
                        mergeLengthIndex.push(isMergeNum);
                    }
                }
            }
            return { index: mergeIndex, lengthIndex: mergeLengthIndex };
        };

        const getTableData = () => {
            if (!searchData.bu || !searchData.payoutRoundYear) {
                message.error('Payout Round (Year) and BU must be selected!');
                return;
            }

            const params = getParams(false);
            getFailedReasonAnalysis(params).then((res: any) => {
                closedDataSource.value = res['Closed'];
                const closedIndex = calculateMergeIndex(res['Closed']);
                mergeIndexProxyClosed.value = closedIndex.index;
                mergeLengthIndexProxyClosed.value = closedIndex.lengthIndex;

                nonClosedDataSource.value = res['Non-Closed'];
                const nonClosedIndex = calculateMergeIndex(res['Non-Closed']);
                mergeIndexProxyNonClosed.value = nonClosedIndex.index;
                mergeLengthIndexProxyNonClosed.value =
                    nonClosedIndex.lengthIndex;
            });
        };

        const buList = computed(() => store.state.user.buList);
        const route = useRoute();
        const routeInit = () => {
            const query = route.query;
            if (Object.keys(query).length > 0) {
                searchData.bu = buList.value.find((bu: any) => {
                    return `${bu.id}` === (route.query.bu as string);
                }).nameEn;
                searchData.payoutRoundYear = route.query.year as string;
                if (route.query.quarter) {
                    searchData.payoutRoundMonth = getMonth(
                        parseInt(route.query.quarter as string)
                    );
                }
            }
        };
        onMounted(() => {
            tableHeight.value = window.innerHeight - 270;
            searchData.year = moment().format('YYYY');
            searchData.payoutRoundYear = moment().format('YYYY');

            const array = getMonth(moment().quarter());
            searchData.quarter = array;
            searchData.payoutRoundMonth = array;
            nextTick(() => {
                routeInit();
                getTableData();
            });
        });
        interface UserStateType {
            value: Array<any>;
            fetching: boolean;
            userData: Array<any>;
        }

        watch(userState.value, () => {
            userState.fetching = false;
            userState.userData = [];
        });
        const timeid = ref<any>();
        const fetchUser = (value: string) => {
            if (!value) {
                return;
            }
            userState.userData = [];
            userState.fetching = true;
            if (timeid.value != null) {
                clearTimeout(timeid.value);
            }
            timeid.value = setTimeout(() => {
                for (let i = 0; i < allDealer.value.length; i++) {
                    if (allDealer.value[i].label.indexOf(value) > -1) {
                        userState.userData.push(allDealer.value[i]);
                    }
                }
                userState.fetching = false;
                clearTimeout(timeid.value);
            }, 1000);
        };

        const changePayoutRoundYear = (value: string) => {
            if (!value) {
                searchData.payoutRoundMonth = [];
            }
        };
        const changeYear = (value: string) => {
            if (!value) {
                searchData.quarter = [];
            }
        };
        const downloadTableData = [
            {
                file: 'Raw Data',
                sign: 1,
            },
            {
                file: 'Report Data',
                sign: 2,
            },
        ];

        return {
            downloadTableData,
            changeYear,
            changePayoutRoundYear,
            openPayoutRoundYear,
            handlerOpenPayoutRoundChange,
            handlerPanelPayoutRoundChange,
            ...toRefs(userState),
            fetchUser,
            usersLst,
            downloadTypeVisible,
            downloadType,
            download,
            handleOk,
            temporaryArray,
            selectPayoutRound,
            closedColumns,
            nonClosedColumns,
            // dataSource,
            closedDataSource,
            nonClosedDataSource,
            tableHeight,
            clearDynamic,
            getTableData,
            isPayoutRound,
            SHOW_PARENT,
            qmOptions,
            openProgramPeriodYear,
            reset,
            initValue,
            ...toRefs(searchData),
            payoutRoundList,
            handlerOpenProgramPeriodChange,
            handlerPanelProgramPeriodChange,
        };
    },
});
